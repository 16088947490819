// import { createRouter, createWebHistory } from 'vue-router';
import VueRouter from 'vue-router'
import Vue from 'vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [

    // path: '/',
    // //路由到的地址(自定义)
    // redirect: '/login',
    // component: () => import('../components/login/login.vue'),
    // hidden: true,
    // children: [
    //     { path: "/index", name: 'index', component: () => import('../components/index/index.vue') },
    //     { path: "/login", name: 'login', component: () => import('../components/login/login.vue') },
    //     // { path: "/cpmpanydes", name: 'cpmpanydes', component: () => import('../components/companyprofile/companyprofile.vue') },
    //     // { path: "/productcenter", name: 'productcenter', component: () => import('../components/productcenter/productcenter.vue') },
    //     // { path: "/productdetails", name: 'productdetails', component: () => import('../components/productdetails/productdetails.vue') },
    //     // { path: "/newsinformation", name: 'newsinformation', component: () => import('../components/newsinformation/newsinformation.vue') },
    //     // { path: "/contactus", name: 'contactus', component: () => import('../components/contactus/contactus.vue') },
    // ],
    // { path: "/", name: 'index', component: () => import('../components/index/index.vue') },
    { path: "/login", name: 'login', meta: { title: '登录' }, component: () => import('../components/login/login.vue') },
    { path: "/register", name: 'register', meta: { title: '注册' }, component: () => import('../components/login/register/register.vue') },
    { path: "/retrievepassword", name: 'retrievepassword', meta: { title: '找回密码' }, component: () => import('../components/login/retrievepassword/retrievepassword.vue') },
    { path: "/resettingpsd", name: 'resettingpsd', meta: { title: '重置密码' }, component: () => import('../components/login/resettingpsd/resettingpsd.vue') },
    {
        path: "/", component: () => import('../components/headerbody/headerbody.vue'),
        redirect: '/index',
        hidden: true,
        children: [
            { path: "/index", name: 'index', meta: { title: '首页' }, component: () => import('../components/index/index.vue') },
            { path: "/searchpage", name: 'searchpage', meta: { title: '搜索' }, component: () => import('../components/searchpage/searchpage.vue') },
            { path: "/purchase", name: 'purchase', meta: { title: '购买' }, component: () => import('../components/purchase/purchase.vue') },
            { path: "/aboutus", name: 'aboutus', meta: { title: '关于我们' }, component: () => import('../components/aboutus/aboutus.vue') },
            { path: "/my", name: 'my', meta: { title: '我的' }, component: () => import('../components/my/my.vue') },
        ]
    },

]





// export default router;
const router = new VueRouter({
    routes
})
export default router;