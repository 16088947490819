import axios from '../../../axios/axios.js'

// 登录 注册
const Index = {
    // 获取购买了的列表（方格展示）
    // get(param) {
    //     return axios.get('/NewsItemInfo/get', param);
    // },
    get(param) {
        return axios.get('/NewsItemInfo/getshortList', param);
    },

    // 添加数据
    add(param) {
        return axios.post('/NewsItemInfo/add', param);
    },
    // 支付成功 (获取支付链接，跳转至第三方支付页面)
    paysuccess(param) {
        return axios.post('/NewsItemInfo/paysuccess', param);
    },
    // 通过这个方法可以获取到列表
    getList(param) {
        return axios.get('/NewsItemInfo/getList', param);
    },
    // 获取价格
    getprice(param) {
        return axios.get('/NewsItemInfo/getprice', param);
    },
    // 获取广告剩余
    getcount(param) {
        return axios.post('/NewsItemInfo/getcount', param);
    },
    // 通过给与参数搜索，后台用
    getOrderList(param) {
        return axios.get('/NewsItemInfo/getAnyList', param);
    },
}


export {
    Index
}