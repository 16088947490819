<template>
  <div id="app">

    <!-- 路由 -->
    <router-view />
  </div>
</template>

<script>
  import Index from './components/index/index.vue'


  export default {
    name: 'App',
    components: {
      Index
    }
  }
</script>

<style>
  /* #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    margin-top: 60px;
  } */
  #app,
  html,
  body {
    height: 100%;
  }

  * {
    margin: 0;
    padding: 0;
  }

  /*滚动条宽度和颜色设置*/
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    background-color: #EFEFEF;
  }

  /*滚动条轨道的颜色*/
  ::-webkit-scrollbar-track {
    background-color: #EFEFEF;
  }

  /*滚动条的小圆球颜色*/
  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
  }
</style>