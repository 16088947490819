import axios from "axios";
import qs from "qs";
// axios.defaults.baseURL = ''  //正式
// axios.defaults.baseURL = 'http://47.107.122.112:8080/cdps' //旧
// axios.defaults.baseURL = 'http://47.107.122.112:8188/cdps' //测试

axios.defaults.baseURL = 'http://47.94.213.0:21212/' //新

//post请求头
axios.defaults.headers.post["Content-Type"] = "application/x-www-form-urlencoded;charset=UTF-8";
//允许跨域携带cookie信息
axios.defaults.withCredentials = true;
import { Message } from 'element-ui'
//设置超时
axios.defaults.timeout = 15000;
axios.interceptors.request.use(
    config => {
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
axios.interceptors.response.use(
    response => {
        if (response.status == 200) {
            console.log(response, '33')
            if (response.data.success == 2) {
                Message.error({
                    // offset: 35,
                    message: response.data.message
                })
                return Promise.resolve(response);

            } else {
                return Promise.resolve(response);
            }

        } else {
            Message.error({
                offset: 35,
                message: '请求错误'
            })
            return Promise.reject(response);
        }
    },
    error => {
        Message.error({
            offset: 35,
            message: '请求错误'
        })
        // console.log(error, '请求异常', {
        //     confirmButtonText: '确定',
        //     callback: (action) => {
        //         console.log(action)
        //     }
        // });
    }
);
export default {
    /**
     * @param {String} url 
     * @param {Object} data 
     * @returns Promise
     */
    post(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'post',
                url,
                data: qs.stringify(data),
            })
                .then(res => {
                    // console.log(res, '2dd')
                    // if (res.data.success == 1) {
                    resolve(res.data)
                    // }

                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    get(url, data) {
        return new Promise((resolve, reject) => {
            axios({
                method: 'get',
                url,
                // params: { param: JSON.stringify(data) },//格式实例 param='{type:12}'
                params: data,//格式实例 param='{type:12}'
            })
                .then(res => {
                    // resolve(res)
                    // if (res.data.success == 1) {
                    resolve(res.data)
                    // }
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
};