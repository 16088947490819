<template>
  <div class="bak">
    <article class="isbody" :style="`min-height:${screenHeight}px`">
      <div class="ggw">
        <div class="searchlan">
          <el-input class="search" placeholder="按照简介搜索，例:“百度”。若需要关键字搜索，请在前面加上#，例:“#搜索”"
            @keyup.enter.native="searchSubmit" prefix-icon="el-icon-search" v-model="searchdata">
          </el-input>
          <div class="yedata">
            <div>剩余广告位</div>
            <div>{{ggcount}}</div>
          </div>
        </div>
        <!-- 方格 -->
        <div class="isfgbox">
          <div class="isfg" v-for="(item, index) in xynum" :key="index+1" :ref="`bt` + (index + 1)"
            @mouseenter="clickPop(item, index + 1)" @mouseleave="showPop = false" @click="()=>{
              if(!item.picUrl){
                clickPay(item)
              }else{
                goUrl(item)
              }
            }">
            <img class="showpayimg" v-if="item.picUrl" :src="item.picUrl">
          </div>
        </div>
        <el-popover v-if="showPop" ref="pop" :reference="reference" placement="right" trigger="hover">
          <div class="isfk">
            <img style="width: 40px; height:40px;object-fit:contain"
              :src="itemxy.picUrl?itemxy.picUrl:require('../../images/pay.png')" alt="" />
            <div class="ispay">
              <!-- <div>{{ `(${itemxy.x},${itemxy.y})` }}</div> -->
              <!-- <div class="ispayJg">￥999</div>   -->
              <div v-if="itemxy.title">标题:{{itemxy.title}}</div>
              <div v-if="itemxy.picUrl">url:{{itemxy.url}}</div>
            </div>
          </div>
        </el-popover>
      </div>
    </article>
    <el-dialog :visible="dialogVisible" width="60%" :close-on-click-modal="false" top="5vh" :show-close="false">
      <div class="dialog_dzs">
        <div class="dialog_d"></div>
        <div class="dialog_z"></div>
        <div class="dialog_s">
          <div class="ggheader">
            <div>购买广告</div>
            <i class="el-icon-error isgmtitle" @click="closePay"></i>
          </div>
          <div class="isgmbox">
            <!-- 坐标 -->
            <div>
              <div>
                <div class="btname">
                  <img class="islog" src="../../images/zb.png" alt="" />
                  <div class="blodtitle">选择坐标</div>
                  <div class="istitledes">请输入您想购买的广告位坐标</div>
                </div>
                <div class="inputsty">
                  <div><span>行</span>
                    <el-input type="number" min="0" max="100" :disabled="iszd" v-model="paydata.x" size="small"
                      style="width: 100px;margin:0 40px 0 14px;"></el-input>
                  </div>
                  <div><span>列</span>
                    <el-input type="number" min="0" max="100" :disabled="iszd" v-model="paydata.y" size="small"
                      style="width: 100px;margin-left: 14px;">
                    </el-input>
                  </div>
                </div>
              </div>
            </div>
            <!-- 照片 -->
            <div>
              <div>
                <div class="btname">
                  <img class="islog" src="../../images/tp.png" alt="" />
                  <div class="blodtitle">上传图片</div>
                </div>
                <div class="inputsty">
                  <el-upload :class="{ hide: hideUpload }" action="http://47.94.213.0:21212/NewsItemInfo/uploadImage"
                    :on-progress="beforeAvatarUpload" list-type="picture-card" :limit="1" size="mini"
                    :on-success="uploadsucadd" ref="upload" :on-change="handleChange"
                    accept="image/jpeg,image/jpg,image/png">
                    <i slot="default" class="el-icon-plus"></i>
                    <div slot="file" slot-scope="{file}">
                      <img class="el-upload-list__item-thumbnail" :src="file.url" alt="">
                      <span class="el-upload-list__item-actions">
                        <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                          <i class="el-icon-zoom-in"></i>
                        </span>

                        <span v-if="!disabled" class="el-upload-list__item-delete" @click="handleRemove(file)">
                          <i class="el-icon-delete"></i>
                        </span>
                      </span>
                    </div>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible1" zIndex="999">
                    <img width="100%" :src="dialogImageUrl" alt="">
                  </el-dialog>
                </div>
              </div>
            </div>
            <!-- 网页地址 -->
            <div>
              <div>
                <div class="btname">
                  <img class="islog" style="width: 18px;height: 10px;" src="../../images/wzicon.png" alt="" />
                  <div class="blodtitle">网页地址</div>
                </div>
                <div class="inputsty">
                  <el-input v-model="paydata.url" size="small"
                    placeholder="请输入网页地址,https://, http://, ftp://, rtsp://, mms://”开头"></el-input>
                </div>
              </div>
            </div>
            <!-- 网站简介 -->
            <div>
              <div>
                <div class="btname">
                  <img class="islog" src="../../images/jj.png" alt="" />
                  <div class="blodtitle">网站简介</div>
                  <div class="istitledes" style="color:#F53A00">·鼠标悬浮于广告上方时，将展示您配置的网站简介</div>
                </div>
                <div class="inputsty">
                  <el-input v-model="paydata.title" :rows="2" resize="none" type="textarea" placeholder="请输入网址简介"
                    maxlength="200" show-word-limit size="small"></el-input>
                </div>
              </div>
            </div>
            <!-- 关键词 -->
            <div>
              <div>
                <div class="btname">
                  <img class="islog" style="width: 18px;height: 10px;" src="../../images/key.png" alt="" />
                  <div class="blodtitle">关键词</div>
                </div>
                <div class="inputsty">
                  <el-input v-model="paydata.keyword" :rows="2" resize="none" type="textarea"
                    placeholder="请输入关键词，多个请用‘,’分割" maxlength="200" show-word-limit size="small"></el-input>
                </div>
              </div>
            </div>
            <!-- 支付方式 -->
            <!-- <div>
              <div>
                <div class="btname">
                  <img class="islog" src="../../images/payway.png" alt="" />
                  <div class="blodtitle">支付方式</div>
                  <div class="iszf">
                    <div :class="radiopay==1?'iszfchange':''" @click="paychange(1)">
                      <div>支付宝</div><img class="payfs " src="../../images/zfb.png" alt="">
                    </div>
                    <div :class="radiopay==2?'iszfchange':''" @click="paychange(2)">
                      <div>微信</div><img class="payfs" src="../../images/wx.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

          </div>
          <!-- 底部按钮 -->
          <div class="footerPay" @click="submitPays">
            <div>立即支付</div>
            <div v-if="priceloading">￥{{payprice}}</div>
            <div v-else><i class="el-icon-loading"></i> </div>
          </div>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span> -->
    </el-dialog>
    <!-- 支付二维码 弹框-->
    <el-dialog :visible="dialogVisiblePay" width="480px" :close-on-click-modal="false" :show-close="false" top="15vh"
      class="mypay">
      <div class="paytk">
        <div class="zfbj">
          <div class="paytitle">百万像素</div>
          <div class="zfscan">
            <!-- <img :src="http://47.94.213.0:21212/img2/20240411200828463183.png" alt=""> -->
            <img :src="paybackdata.url" alt="">
          </div>
        </div>
      </div>
      <div class="userpay">应付金额：￥{{paybackdata.price}}</div>
      <div class="paybtn">
        <el-button @click="dialogVisiblePay = false">取 消</el-button>
        <el-button type="primary" @click="ipayed" :loading="payloading">我已支付</el-button>
      </div>

    </el-dialog>

  </div>
</template>
<script>
  // import Purchase from ".././purchase/purchase";
  import { Index } from '../index/api/index.js'
  export default {
    // components: {
    //   Purchase,
    // },
    data() {
      return {
        payloading: false,
        // screenHeight: document.body.clientHeight - 100,
        screenHeight: document.body.clientHeight - 120,
        searchdata: "",
        input: '',//
        xynum: [], //坐标集合
        payxynum: [],//已购买的坐标
        showPop: false,
        reference: {},
        activeId: "", // 保存当前激活的refrence id
        itemxy: "", //坐标
        // ------------------
        dialogVisible: false,
        // ---------------
        dialogImageUrl: '',
        dialogVisible1: false,
        disabled: false,
        inputh: '',
        inputl: '',
        radiopay: 1,//1支付宝 2微信
        // xwdtindex: ''
        paydata: {
          title: '',//简介
          url: '',//
          x: '',//
          y: '',//
          keyword: '',//
          userId: '',//
          picUrl: '',//
        },
        iszd: false,//用户指定购买位置
        hideUpload: false,
        limitCount: 1,
        payprice: 0,
        priceloading: false,//价格加载中
        ggcount: '0',

        dialogVisiblePay: false,//支付二维码
        paybackdata: {},//

      };
    },
    props: ['xwdtindex'],
    watch: {
      // 监听
      xwdtindex: function (newV, oldV) {
        let _that = this
        _that.paydata.x = ''
        _that.paydata.y = ''
        _that.dialogVisible = newV
        // console.log(newV, 'new1')
        // console.log(oldV, 'old1')
        this.getPrice()
      },
      // 路由监控，含有timer，则显示购买弹框
      $route: {
        handler: function (val, oldVal) {
          if (val.query.timer) {
            this.dialogVisible = true
            this.getPrice()//这里请求有点问题，但必须请求
          }
        },
        // 深度观察监听
        deep: true,
        immediate: true
      },
    },
    created() {
      // console.log(this.$route)
      this.userdata = localStorage.getItem('USERID') ? JSON.parse(localStorage.getItem('USERID')) : {}
      this.paydata.userId = this.userdata.userId
      // this.coordinateCount();
      this.getInfo()
    },
    mounted() {
      // this.getInfo()
      this.getSyCount()
    },
    methods: {
      // 获取广告剩余
      getSyCount() {
        Index.getcount().then(res => {
          console.log(res)
          this.ggcount = res.message
        })
      },
      // 去对应网站 跳转有问题，地址不规范等问题 需要有http https wws.....
      goUrl(item) {
        // window.open(item.url)
        if (item.url.indexOf('https://') != -1) {
          window.open(item.url, '_blank')
        } else {
          window.open(`https://${item.url}`, '_blank')
        }

        // else if (item.url.indexOf('http://') != -1) {

        // }

        // window.open('https://www.baidu.com', "_blank")
        // window.open(item.url, '_blank')
      },
      // 刷新当前页面数据
      RefreshPage1(num) {
        // console.log(num)
        this.userdata = localStorage.getItem('USERID') ? JSON.parse(localStorage.getItem('USERID')) : {}
        this.paydata.userId = this.userdata.userId || ''
        this.getInfo()
        // this.userdata = localStorage.getItem('USERID') ? JSON.parse(localStorage.getItem('USERID')) : ''
      },
      beforeAvatarUpload(event, file, fileList) {
        let testmsg = /^image\/(jpeg|png|jpg)$/.test(file.raw.type)
        console.log(file, '222s2')
        if (!testmsg) {
          this.$message.error('上传图片格式不对!请上传jpeg，png，jpg')
          this.paydata.picUrl = ''
          this.hideUpload = false;
          this.$refs.upload.clearFiles();
          return
        }
        return testmsg
      },
      // 搜索
      searchSubmit() {
        localStorage.setItem('changeActive', '2')
        this.$parent.fatherMethod(2);//触发父组件
        this.$router.push(
          {
            path: '/searchpage',
            query: {
              value: this.searchdata
            }
          }
        )
      },
      // 获取广告列表
      getInfo() {
        let data = {
          pageSize: 10000,
          pageNum: 1,
          param: '{ type: 2 }'
        }
        Index.get(data).then(res => {
          // console.log(res, '5555')
          this.payxynum = res.message.list || []
          // console.log(this.payxynum)
          this.coordinateCount();
        })
      },
      // 切换支付方式
      paychange(type) {
        this.radiopay = type
      },

      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible1 = true;
      },
      handleDownload(file) {
        console.log(file);
      },
      // 点击购买
      clickPay(item) {
        if (!this.userdata.userId) {
          this.$confirm('您未登录，前往登录?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            this.$router.push('/login')
          }).catch(() => { })
          return
        }
        this.paydata.x = item.x
        this.paydata.y = item.y
        this.iszd = true
        this.dialogVisible = true
        this.getPrice()
      },
      // 获取对应坐标的价格
      getPrice() {
        this.priceloading = false
        Index.getprice().then(res => {
          console.log(res)
          if (res.success == 1) {
            this.payprice = res.message
            this.priceloading = true
          }
        })
      },
      // 关闭购买弹框
      closePay() {
        this.dialogVisible = false
        this.iszd = false
        this.resetPayData()
        // this.xwdtindex = 1

        this.$parent.fatherMethod(1);//触发父组件
        this.$router.push('/index')
        this.$emit("clickIsPreview", false)
      },

      // 坐标计算赋值
      coordinateCount() {
        let arr = [];
        for (let i = 1; i <= 100; i++) {
          for (let j = 1; j <= 100; j++) {
            let obj = {
              x: i,
              y: j,
              title: '',//简介
              url: '',//
              keyword: '',//
              userId: '',//
              picUrl: '',// 
            };
            arr.push(obj);
          }
        }

        for (let i = 0; i < this.payxynum.length; i++) {
          let x = this.payxynum[i].x
          let y = this.payxynum[i].y
          // （x - 1）* 100 + y - 1
          let index = (x - 1) * 100 + y - 1//计算坐标
          arr.splice(index, 1, this.payxynum[i])
        }

        // 可再次循环，将已有的广告位加入并显示
        // for (let i = 0; i < arr.length; i++) {
        //   for (let j = 0; j < this.payxynum.length; j++) {
        //     if ((arr[i].x == this.payxynum[j].x) && (arr[i].y == this.payxynum[j].y)) {
        //       // console.log(arr[i])
        //       // console.log(this.payxynum[j])
        //       arr[i].title = this.payxynum[j].title
        //       arr[i].url = this.payxynum[j].url
        //       // arr[i].keyword = this.payxynum[j].keyword
        //       // arr[i].userId = this.payxynum[j].userId
        //       arr[i].picUrl = this.payxynum[j].picUrl
        //     }
        //   }
        // }
        this.xynum = arr;
        console.log(this.xynum)
      },
      clickPop(item, index) {
        this.showPop = true;
        // this.itemxy = `x：${item.x},y：${item.y}`;
        this.itemxy = item;
        // console.log(item);
        // console.log(index);
        // 这个操作是为了避免与源码中的点击reference doToggle方法冲突
        if (this.activeId === index && this.showPop) return;
        // this.showPop = false;
        this.activeId = index;
        // 因为reference是需要获取dom的引用 所以需要是$el
        this.reference = this.$refs["bt" + index][0];
        this.$nextTick(() => {
          // 等待显示的popover销毁后再 重新渲染新的popover
          // this.showPop = true;
          this.$nextTick(() => {
            // 此时才能获取refs引用
            this.$refs.pop.doShow();
          });
        });
      },
      // 移除图片
      handleRemove(file) {
        console.log(file);
        this.paydata.picUrl = ''
        this.hideUpload = false;
        this.$refs.upload.clearFiles();
      },
      handleChange(fileList) {
        console.log(fileList, 'dddd')
        this.hideUpload = fileList.length >= this.limitCount;
        this.hideUpload = true; //此时值为ture时 才会执行隐藏

        // let hz = fileList.name.split('.')
        // let type = hz[hz.length - 1]
        // console.log(type)
        // let testmsg = /^image\/(jpeg|png|jpg)$/.test(type)
        // if (!testmsg) {
        //   this.$message.error('上传图片格式不对!请上传jpeg，png，jpg')
        //   this.paydata.picUrl = ''
        //   this.hideUpload = false;
        //   this.$refs.upload.clearFiles();
        //   return
        // }
        // return testmsg
      },
      // 新增时上传图片成功回调
      uploadsucadd(response, file, fileList) {
        if (response.success == 1) {
          this.paydata.picUrl = response.message
          this.hideUpload = fileList.length >= this.limitCount;
        } else {
          // this.handleRemove(file)
        }
        console.log(this.paydata.picUrl)
        console.log(response)
      },
      // 提交支付
      submitPays() {
        console.log(this.paydata)
        // const reg = ['https', 'http', 'ftp', 'rtsp', 'mms']

        // return

        if (this.paydata.x == '') {
          this.$message.warning('请输入x轴坐标')
          return
        } else if (this.paydata.y == '') {
          this.$message.warning('请输入y轴坐标')
          return
        } else if (this.paydata.picUrl == '') {
          this.$message.warning('请上传图片')
          return
        } else if (this.paydata.url == '') {
          this.$message.warning('请输入网站地址')
          return
        } else if (this.paydata.title == '') {
          this.$message.warning('请输入简介')
          return
        } else if (this.paydata.keyword == '') {
          this.$message.warning('请输入关键字')
          return
        }
        let url = this.paydata.url
        if (url.indexOf('https') == -1
          && url.indexOf('http') == -1
          && url.indexOf('ftp') == -1
          && url.indexOf('rtsp') == -1
          && url.indexOf('mms') == -1) {
          this.$message.warning('网站地址需以“https://, http://, ftp://, rtsp://, mms://”开头')
          return
        }
        if (this.paydata.keyword) {
          // 将用户输入的关键字中的逗号过滤一遍，无论是中英文逗号，都改成英文逗号
          this.paydata.keyword = this.paydata.keyword.replace(/,/g, ',')
          this.paydata.keyword = this.paydata.keyword.replace(/，/g, ',')
        }

        Index.add(this.paydata).then(res => {
          console.log(res, '5255')
          if (res.success == 1) {
            this.paybackdata = JSON.parse(res.message)
            this.dialogVisiblePay = true
          } else {
            this.$message.error(res.message)
          }

          return
          // if (res.success == 1) {
          //   window.open(res.message, '_self')
          //   this.$message({
          //     message: '添加成功，前往支付',
          //     type: 'success'
          //   });
          //   this.resetPayData()
          // }
        })
      },

      // 清除购买填写信息
      resetPayData() {
        this.paydata.x = ''
        this.paydata.y = ''
        this.paydata.picUrl = ''
        this.paydata.url = ''
        this.paydata.title = ''
        this.paydata.keyword = ''
        this.hideUpload = false;
        this.dialogVisible = false
        this.iszd = false
        this.$refs.upload.clearFiles();
      },
      // 我已支付
      ipayed() {
        this.payloading = true
        // setTimeout(() => {
        //   this.payloading = false
        //   this.dialogVisiblePay = false
        //   this.getInfo()//支付后刷新页面
        //   this.resetPayData()
        // }, 2000)
        this.paybackdata
        let data = {
          pageSize: 1,
          pageNum: 30,
          // param: { orderId: this.paybackdata.OutTradeNo }
          orderid: this.paybackdata.OutTradeNo
        }
        Index.getOrderList(data).then(res => {
          console.log(res)
          // 0 未支付  1已支付 2审核不通过 3已支付，审核通过
          if (res.success == '1') {
            let obj = res.message.list[0]
            if (obj.ispay == 0) {
              this.$message.warning('您还未支付')
              this.payloading = false
            } else if (obj.ispay == '1') {
              this.$message.success('已支付')
              this.payloading = false
              this.dialogVisiblePay = false
              this.getInfo()//支付后刷新页面
              this.resetPayData()
            }
          }

          //   this.payloading = false
          //   this.dialogVisiblePay = false
          //   this.getInfo()//支付后刷新页面
          //   this.resetPayData()
        })

      }
    },
  };
</script>
<style scoped>
  .isbody {
    background: #fbf7f4;
  }

  .ggw {
    /* width: 55%; */
    width: 1000px;
    height: 100%;
    /* background: rebeccapurple; */
    margin: 0 auto;
  }

  .searchlan {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;
  }

  .search {
    width: 72%;
    z-index: 0;
  }

  .yedata {
    width: 25%;
    display: flex;
    background: #fff;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
  }

  .yedata>div {
    padding: 0 20px;
  }

  .yedata>div:first-child {
    border-right: 2px solid #ffcc70;
    color: #08003b;
  }

  .yedata>div:last-child {
    font-weight: bold;
    color: #f53a00;
  }

  ::v-deep .el-input__inner {
    border-radius: 15px;
  }

  .isfgbox {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
    padding-bottom: 60px;
  }

  .isfg {
    width: 10px;
    height: 10px;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid rgb(238, 238, 238);
    position: relative;
  }

  .showpayimg {
    width: 100%;
    height: 100%;
    /* background: red; */
    position: absolute;
    top: 0;
    left: 0;
  }

  .hover_con {
    position: fixed;
    max-width: 220px;
    padding: 10px;
    border: 1px solid #666;
    background: #ccc;
  }

  .isfk {
    display: flex;
    align-items: center;
    align-content: center;
  }

  .ispay {
    margin-left: 10px;
  }

  .ispayJg {
    color: #f53a00;
    font-weight: bold;
  }

  /* -----------------------------购买弹框--------------------------- */
  .isgmbox {
    padding: 20px 40px;
  }

  ::v-deep .el-dialog {
    background: #fff0;
    /* background: #fff; */
    box-shadow: none;
  }

  .dialog_dzs {
    position: relative;
    /* height: 680px; */
    height: 700px;
    height: 680px;
    margin: 0 auto;
    width: 480px;
  }

  .dialog_d {
    width: 480px;
    /* height: 650px; */
    height: 680px;
    background: #888ada;
    position: absolute;
    left: 0;
    bottom: 0;
    transform-origin: left bottom;
    transform: rotate(-10deg);
    border-radius: 10px;
  }

  .dialog_z {
    width: 480px;
    /* height: 600px; */
    height: 620px;
    background: #f9b24f;
    position: absolute;
    left: 0;
    bottom: 0;
    transform-origin: right bottom;
    transform: rotate(8deg);
    border-radius: 10px;
  }

  .dialog_s {
    width: 480px;
    /* height: 700px; */
    height: 720px;
    background: #fff;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 10px;
    box-shadow: 0px 1px 4px 1px;
  }

  .ggheader {
    display: flex;
    height: 60px;
    align-items: center;
    border-bottom: 2px solid #1b1b1b;
    justify-content: space-between;
    padding: 0 20px;
  }

  .ggheader>div {
    margin-left: 30px;
    font-size: 18px;
    color: #1b1b1b;
    font-weight: bold;
  }

  .isgmtitle {
    font-size: 18px;
    cursor: pointer;
  }

  .btname {
    display: flex;
    align-items: center;
  }

  .islog {
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }

  .blodtitle {
    font-size: 16px;
    font-weight: bold;
    color: #1b1b1b;

  }

  .istitledes {
    font-size: 12px;
    color: #A1A0A8;
    margin-left: 20px;
  }

  .inputsty {
    display: flex;
    margin: 10px 0 20px 30px;
    color: #1B1B1B;
  }


  ::v-deep .el-upload--picture-card {
    width: 80px;
    height: 80px;
  }

  ::v-deep .hide .el-upload--picture-card {
    display: none !important;
  }

  ::v-deep .el-upload {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }

  ::v-deep .el-upload-list--picture-card .el-upload-list__item {
    width: 80px;
    height: 80px;
    line-height: 80px;
    margin: 0 !important;
  }

  ::v-deep .el-upload-list--picture-card .el-upload-list__item-thumbnail {
    width: 80px;
    height: 80px;
    line-height: 80px;
  }

  ::v-deep .avatar {
    width: 80px;
    height: 80px;
  }

  .footerPay {
    width: 100%;
    height: 80px;
    background: #020229;
    position: absolute;
    bottom: 0;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    font-size: 24px;
    font-weight: bold;
    color: #FBF7F4;
    /* justify-content: space-around; */
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .footerPay>div {
    margin: 0 20px;
  }

  .iszf {
    display: flex;
  }

  .iszf>div {
    width: 80px;
    height: 30px;
    border: 1px solid rgb(212, 212, 212);
    border-radius: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    font-size: 12px;
    margin: 0 20px;
    cursor: pointer;
  }

  .iszfchange {
    border: 1px solid #30a2ff !important;
  }


  .payfs {
    width: 26px;
    height: 26px;
  }

  ::v-deep .mypay>.el-dialog {
    /* background: #fff0; */
    background: #fff;
    box-shadow: none;

  }

  .paytk {
    height: 480px;
  }

  .zfbj {
    margin: 0 auto;
    width: 80%;
    height: 100%;
    background: url(../../assets/zfbj.png) no-repeat;
    background-size: 100% 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }

  .zfscan {
    width: 80%;
    position: absolute;
    bottom: 20px;
  }

  .zfscan>img {
    width: 100%;
    height: 100%;
  }

  .paybtn {
    text-align: center;
  }

  .userpay {
    text-align: center;
    margin: 0px 10px 8px 10px;
    font-size: 20px;
    color: red;
  }

  .paytitle {
    position: absolute;
    top: 64px;
    font-size: 28px;
    color: #e9a862;
  }
</style>